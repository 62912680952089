const hiddenFooterLocationIds = [
  // test
  "67af42976518bcadcd3d8073",
  // MAT-5535
  "6772aedffd86ecb1c172e53f",
  "6789d1f940e120fee851863f",
  "6772aeec60ce0f3ce48c9701",
  "6789cfc05cb904189c45db7b",
];

export default hiddenFooterLocationIds;
