import React, { useCallback, useEffect, useState } from "react";
import mobileCtaActions from "../components/modules/mobileCtaActions";
import { ModernSmsIcon, SmsIcon } from "../components/Icons/Icons";
import styled from "styled-components";
import { strings } from "../utils/localization";
import useAnalytics from "../utils/useAnalytics";
import { useLocation, useParams } from "react-router-dom";

const variantStyles = {
  small: {
    actionButton: `
      height: 30px;
    `,
    actionText: `
      font-size: 13px;
    `,
    iconMaxHeight: "18px",
  },
  medium: {
    actionButton: `
      height: 35px;
    `,
    actionText: `
      font-size: 15px;
    `,
    iconMaxHeight: "18px",
  },
  large: {
    actionButton: `
      height: 48px;
    `,
    actionText: `
      font-size: 17px;
    `,
    iconMaxHeight: "20px",
  },
};

const TextMePreviewContainer = styled.div`
  width: 100%;
  position: relative;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-family: Arial, sans-serif;
`;

const TextMePreviewContent = styled.div`
  padding: 0px 1rem;
  top: auto;
  width: 100%;
  display: flex;
  gap: 0.8rem;
  padding-top: ${({ paddingTop }) => paddingTop};

  position: sticky;
  font-weight: 600;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${({ displayCallMe }) =>
    displayCallMe ? "space-between" : "flex-end"};
`;

const TextMeActionButton = styled.a`
  padding: 0 12px;
  border-radius: 8px;
  width: 48%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  ${({ variantStyle }) => variantStyle.actionButton}
`;

const TextMeActionText = styled.p`
  text-align: center;
  font-size: 17px;
  margin: 0px;
  margin-left: 3px;
  ${({ variantStyle }) => variantStyle.actionText}
`;

const Icon = styled.div`
  max-height: 20px;

  svg {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: ${({ maxHeight }) => maxHeight};
  }
`;

const danielLocationIds = [
  "66423a2154c0270013a7d589",
  "6671c35e39b7f088eca4ef9c",
];

const MobileCta = ({ match }) => {
  const [data, setData] = useState();
  const [language, setLanguage] = useState("en");
  let { lang, token } = useParams();
  const [originUrl, setOriginUrl] = useState();
  const [currentOs, setCurrentOs] = useState();
  const { sendCommEngagementEvent, sendCtaInteractionEvent } = useAnalytics(
    data?.GATrackingNumber
  );
  const [isGoogleApp, setIsGoogleApp] = useState(false);
  const [displayCallMe, setDisplayCallMe] = useState(true);
  const location = useLocation();

  useEffect(() => {
    handleUrl();
  }, [location]);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    const isGoogleApp = userAgent.includes("GSA/");

    setIsGoogleApp(isGoogleApp);
  }, []);

  useEffect(() => {
    const id = data?._location_id;
    const isDanielLocation = danielLocationIds.includes(id);
    if (isDanielLocation) {
      setDisplayCallMe(false);
    }
  }, [data]);

  const onMessageReceivedFromIframe = useCallback(
    ({ data: dataInfo }) => {
      if (dataInfo?.type === "MATADOR-UPDATE-SETTINGS-MOBILE-CTA") {
        const info = dataInfo;
        info.paddingTop = "0.1px";
        setData({
          ...data,
          ...info,
        });
      }
    },
    [data]
  );

  useEffect(() => {
    window.addEventListener("message", onMessageReceivedFromIframe);
    return () =>
      window.removeEventListener("message", onMessageReceivedFromIframe);
  }, [onMessageReceivedFromIframe]);

  useEffect(() => {
    let value = lang || "en";
    if (value === "es") {
      value = "sp";
    }

    strings.setLanguage(value === "sp" ? "es" : value);
    setLanguage(value);
  }, [lang]);

  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
      setCurrentOs("iOS");
    } else if (userAgent.match(/Android/i)) {
      setCurrentOs("Android");
    } else {
      setCurrentOs("unknown");
    }
  };

  useEffect(() => {
    getMobileOperatingSystem();
    getData();
  }, []);

  useEffect(() => {
    if (data) {
      sendCommEngagementEvent({ widget: "Mobile CTA", status: "load" });
    }
  }, [data]);

  const handleUrl = () => {
    let params = new URLSearchParams(location.search);
    let urlValue = params.get("url");

    if (location.hash) {
      const newUrl = urlValue + location.hash;
      setOriginUrl(newUrl);
      urlValue = newUrl;
    } else {
      setOriginUrl(urlValue);
    }
  };

  const getData = async () => {
    const result = await mobileCtaActions.getData(token);

    if (result.success) {
      setData({ ...result.data, paddingTop: "5px" });
    }
  };

  if (!data) return null;
  const { primaryBackgroundColor } = data;

  const getHref = () => {
    const { textMeButton, phone_number } = data;
    const { templateText, addLinkToBody } = textMeButton;

    const languageCode = language.split("-")[0]; // Extracting the language code

    if (templateText) {
      const text = templateText[languageCode || "en"] || "";

      const txtddLinkToBody = addLinkToBody ? originUrl : "";
      const href = `sms://${phone_number}${
        currentOs === "iOS" ? "/&" : "?"
      }body=${text} ${txtddLinkToBody}`;

      return href;
    }
  };

  const onCallUsClicked = (event) => {
    sendCtaInteractionEvent({ widget: "Mobile CTA" });

    var isChromeOnIOS =
      /CriOS/i.test(navigator.userAgent) &&
      /iphone|ipod|ipad/i.test(navigator.userAgent);

    if (isGoogleApp || isChromeOnIOS) {
      const message = {
        eventName: "matador_call_me_button_clicked",
        phoneNumber: data.phone_number,
        system: getMobileOperatingSystem(),
      };
      window.top.postMessage(message, "*");

      return event.preventDefault();
    }
  };

  const onTextUsClicked = () => {
    const { textMeButton, phone_number } = data;
    const { templateText, addLinkToBody } = textMeButton;
    const languageCode = language.split("-")[0];
    let textForSms = "";

    if (templateText) {
      const text = templateText[languageCode || "en"] || "";

      const txtddLinkToBody = addLinkToBody ? originUrl : "";
      textForSms = `${text} ${txtddLinkToBody}`;
    }

    if (isGoogleApp) {
      const message = {
        eventName: "matador_text_us_button_clicked",
        phoneNumber: phone_number,
        text: textForSms,
        system: getMobileOperatingSystem(),
      };
      window.top.postMessage(message, "*");
    }
    sendCtaInteractionEvent({ widget: "Mobile CTA" });
  };

  const getCallMeTitle = () => {
    return data.callMeButton?.name?.[language] || strings.CALL_US;
  };

  const getTextMeTitle = () => {
    return data.textMeButton?.name?.[language] || strings.TEXT_US;
  };

  if (!data) return null;
  const { variant, displayBackground } = data;

  const variantStyle = variantStyles[variant] || variantStyles.large;

  return (
    <TextMePreviewContainer
      style={{ backgroundColor: displayBackground && primaryBackgroundColor }}
    >
      <TextMePreviewContent paddingTop={data.paddingTop || "5px"}>
        <ButtonWrapper displayCallMe={displayCallMe}>
          {displayCallMe && (
            <TextMeActionButton
              href={`tel:${data.phone_number}`}
              target={currentOs === "iOS" ? "_parent" : ""}
              variantStyle={variantStyle}
              onClick={onCallUsClicked}
              style={{ backgroundColor: data?.callMeButton?.backgroundColor }}
            >
              <Icon
                variantStyle={variantStyle}
                maxHeight={variantStyle.iconMaxHeight}
              >
                {ModernSmsIcon({ fill: data?.callMeButton?.textColor })}
              </Icon>
              <TextMeActionText
                variantStyle={variantStyle}
                style={{ color: data?.callMeButton?.textColor }}
              >
                {getCallMeTitle()}
              </TextMeActionText>
            </TextMeActionButton>
          )}
          <TextMeActionButton
            href={getHref()}
            target={currentOs === "iOS" ? "_parent" : ""}
            variantStyle={variantStyle}
            onClick={onTextUsClicked}
            style={{ backgroundColor: data.textMeButton.backgroundColor }}
          >
            <Icon
              variantStyle={variantStyle}
              maxHeight={variantStyle.iconMaxHeight}
            >
              {SmsIcon({ fill: data.textMeButton.textColor })}
            </Icon>
            <TextMeActionText
              variantStyle={variantStyle}
              style={{ color: data.textMeButton.textColor }}
            >
              {getTextMeTitle()}
            </TextMeActionText>
          </TextMeActionButton>
        </ButtonWrapper>
      </TextMePreviewContent>
    </TextMePreviewContainer>
  );
};

export default MobileCta;
