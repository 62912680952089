import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { strings } from "../../../utils/localization";
import { ReactComponent as NewLogo } from "./assets/new_logo.svg";
import AcceptTermsFooter from "./AcceptTermsFooter";
import { useChatContext } from "../../contexts/ChatContext";
import hiddenFooterLocationIds from "../../../utils/hiddenFooterLocationIds";
import customTermLocationIds from "./data/customTermLocationIds";

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: ${({ hasChatId }) =>
    hasChatId ? "space-between" : "center"};
  background-color: #f6f7fb;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 5px 16px;
`;

const PoweredByLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  flex-direction: ${({ direction }) => direction};
`;

const Label = styled.span`
  font-weight: 500;
  font-size: 10px;
  line-height: 130%;
  color: #222222;
  margin-right: 5px;
`;

const EmptyContainer = styled.div`
  height: 5px;
  background-color: #f6f7fb;
`;

const Wrapper = styled.div`
  padding-right: 0px;
`;

const DefaultFooter = ({ chatData }) => {
  const { chatId, readOnly } = useChatContext();
  const [showPoweredBy, setShowPoweredBy] = useState(true);

  useEffect(() => {
    const exists = customTermLocationIds[chatData?._location_id];
    setShowPoweredBy(!exists);
  }, [chatData]);

  if (readOnly) return null;

  if (hiddenFooterLocationIds?.includes(chatData?._location_id))
    return <EmptyContainer />;

  return (
    <Container hasChatId={!!chatId}>
      {chatId && (
        <Wrapper>
          <AcceptTermsFooter
            color={chatData?.termsLink}
            termsLink={chatData?.termsLink}
            margin={0}
            locationId={chatData?._location_id}
          />
        </Wrapper>
      )}
      {showPoweredBy && (
        <PoweredByLink
          target="_blank"
          aria-label="Matador"
          href="http://matador.ai/?utm_source=matador%20connect%20widget&utm_medium=link&utm_campaign=widget%20referral"
          direction={chatId ? "column" : "row"}
        >
          {!chatId && <Label>{strings.POWERED_BY}</Label>}

          <NewLogo />
        </PoweredByLink>
      )}
    </Container>
  );
};

export default DefaultFooter;
