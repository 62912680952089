import React from "react";
import styled from "styled-components";
import { strings } from "../../../utils/localization";
import customTermLocationIds from "./data/customTermLocationIds";

const FooterContainer = styled.div`
  width: 100%;
  padding-left: ${(props) => props.paddingLeft + "px"};
`;

const ConsentText = styled.div`
  margin-top: ${(props) => props.margin + "px"};
  margin-bottom: ${(props) => props.margin + "px"};
  display: inline-flex;
  align-items: center;
`;

const TermsLink = styled.a`
  color: ${(props) => props.color} !important;
  text-decoration: none;
  font-size: 9px !important;

  &:hover {
    text-decoration: underline;
  }
`;

const Checkbox = styled.input`
  width: 18px;
  height: 18px;
  margin-right: 5px;
  border-radius: 10px;

  &:checked {
    accent-color: ${(props) => props.color};
  }
`;

const SmallCheckbox = styled.input`
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border-radius: 10px;

  &:checked {
    accent-color: ${(props) => props.color};
  }
`;

const Text = styled.div`
  margin: 0;
  font-size: 9px !important;
  text-align: left;
`;

const AcceptTermsFooter = ({
  color,
  termsLink = "http://matador.ai/?utm_source=matador%20connect%20widget&utm_medium=link&utm_campaign=widget%20referral",
  paddingLeft = 0,
  margin = 10,
  type = "default",
  locationId,
  currentUrl,
}) => {
  const language = strings.getLanguage() || "en";

  let links;
  if (locationId === "65aea1867640ce001303232f") {
    links = customTermLocationIds[locationId](currentUrl);
  } else {
    links = customTermLocationIds[locationId] || {};
  }

  const currentTermsLink = links.termsLink || termsLink;
  const currentPrivacyLink = links.privacyLink;

  const renderConsentText = () => {
    if (locationId === "597f6f18cf81540021044f6e") {
      return (
        <ConsentText margin={margin}>
          <Checkbox checked type="checkbox" color={color} readOnly />
          <Text>
            {strings.CONSENT_TEXT_NEW}{" "}
            <TermsLink
              color={color}
              target="_blank"
              href={termsLink}
              aria-label="terms link"
            >
              {strings.PRIVACY_POLICY}
            </TermsLink>
          </Text>
        </ConsentText>
      );
    }

    if (locationId === "65773a4c9211f000142b1eb4") {
      return (
        <ConsentText margin={margin}>
          <Checkbox checked={true} readOnly type="checkbox" color={color} />
          <Text>
            By interacting with this chat module, you acknowledge and consent to
            the recording and sharing of these communications with third party
            affiliates and non-affiliates for business and marketing purposes.
            To learn more, please visit our{" "}
            <TermsLink
              color={color}
              target="_blank"
              href="https://www.simpsongm.com/privacy.htm"
            >
              privacy policy
            </TermsLink>
          </Text>
        </ConsentText>
      );
    }

    if (locationId in customTermLocationIds) {
      return (
        <ConsentText margin={margin}>
          <Checkbox checked type="checkbox" color={color} readOnly />
          <Text>
            By clicking “Submit,” I understand that Dealership may call, text,
            or email me with offers or information about their products and
            service, including through automated means. I understand that
            consent is not a condition of purchase.{" "}
            <TermsLink
              color={color}
              target="_blank"
              href={currentTermsLink}
              aria-label="Privacy link"
            >
              Terms of Use
            </TermsLink>{" "}
            and{" "}
            <TermsLink
              color={color}
              target="_blank"
              href={currentPrivacyLink}
              aria-label="Terms link"
            >
              Privacy Policy
            </TermsLink>{" "}
            apply. Message and data rates may apply. Text STOP to stop.
          </Text>
        </ConsentText>
      );
    }

    if (type === "cta") {
      return (
        <ConsentText margin={margin}>
          <SmallCheckbox checked={true} type="checkbox" color={color} />
          <Text>
            {strings.SHORT_CONSENT}{" "}
            <TermsLink
              color={color}
              target="_blank"
              href={termsLink}
              aria-label="Terms link"
            >
              {strings.TERMS}
            </TermsLink>
          </Text>
        </ConsentText>
      );
    }

    if (language === "fr") {
      return (
        <ConsentText margin={margin}>
          <Checkbox checked type="checkbox" color={color} readOnly />
          <Text>
            J'accepte d'être contacté par SMS. J’accepte aussi les{" "}
            <TermsLink
              color={color}
              target="_blank"
              href={termsLink}
              aria-label="Terms link"
            >
              Conditions
            </TermsLink>{" "}
            d’utilisation.
          </Text>
        </ConsentText>
      );
    } else if (language === "es") {
      return (
        <ConsentText margin={margin}>
          <Checkbox checked type="checkbox" color={color} readOnly />
          <Text>
            Acepto ser contactado por SMS. También acepto los{" "}
            <TermsLink
              color={color}
              target="_blank"
              href={termsLink}
              aria-label="Terms link"
            >
              Términos
            </TermsLink>{" "}
            de uso.
          </Text>
        </ConsentText>
      );
    } else {
      return (
        <ConsentText margin={margin}>
          <Checkbox checked type="checkbox" color={color} readOnly />
          <Text>
            {strings.NEW_CONSENT_TEXT}{" "}
            <TermsLink
              color={color}
              target="_blank"
              href={termsLink}
              aria-label="terms link"
            >
              {strings.TERMS_AND_CONDITIONS}
            </TermsLink>
          </Text>
        </ConsentText>
      );
    }
  };

  return (
    <FooterContainer paddingLeft={paddingLeft}>
      <div>{renderConsentText()}</div>
    </FooterContainer>
  );
};

export default AcceptTermsFooter;
