import axios from "axios";
import { urlConfig } from "../../../components/modules/UrlHelper";

class AiChatApi {
  static sendMessage = ({ locationId, param, cancelToken }) => {
    return axios({
      method: "POST",
      url: `${urlConfig.BASE_URL}/superhuman-chat/${locationId}/messages`,
      data: param,
      cancelToken,
    });
  };
  static updateContact = ({ locationId, param, cancelToken }) => {
    return axios({
      method: "POST",
      url: `${urlConfig.BASE_URL}/superhuman-chat/${locationId}/contact`,
      data: param,
      cancelToken,
    });
  };
  static checkMessageStatus = ({ locationId, idempotencyKey, cancelToken }) => {
    return axios({
      method: "GET",
      url: `${urlConfig.BASE_URL}/superhuman-chat/${locationId}/status/${idempotencyKey}`,
      cancelToken,
    });
  };
}

export default AiChatApi;
