export const getFontFamily = (chatData) => {
  if (!chatData) return "Arial, 'Helvetica Neue', sans-serif";

  const token = chatData._token;
  const id = chatData._location_id;
  let fontFamily = "Arial, 'Helvetica Neue', sans-serif";

  if (volvoDealerLocationIds.includes(id)) {
    fontFamily = "Volvo Novum";
  }

  if (
    token === "12423abb841941c552815dac59ee48477267ec50" ||
    token === "489734309b0baf97129d98198f67cf878bacbb85" ||
    token === "25e67694cd84d6cb8471cd7b765077c14315a923" ||
    token === "aeb87d7628e0145574fec7667902769095eab957"
  ) {
    // MAT-3739
    fontFamily = "Arial";
  }

  return fontFamily;
};

export const getCtaFontFamily = (locationId) => {
  let fontFamily =
    'BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif';

  if (volvoDealerLocationIds.includes(locationId)) {
    fontFamily =
      'Volvo Novum, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif';
  }

  if (
    locationId === "67780f5e3fd6d546a196f570" ||
    locationId === "669e73689bbbd1bad1b06a61" // stg test
  ) {
    fontFamily = '"BMW Type Next", sans-serif';
  }

  return fontFamily;
};

const volvoDealerLocationIds = ["6745aad38f06faf6a6ca13ef"];
