const customTermLocationIds = {
  "65b009e83772ad0013643fd0": {
    termsLink: "https://carshop.com/terms",
    privacyLink: "https://carshop.com/privacy-requests",
  },
  "65affdca96d47500136b2a3d": {
    termsLink: "https://carshop.com/terms",
    privacyLink: "https://carshop.com/privacy-requests",
  },
  "65afec12007c5a001325ea72": {
    termsLink: "https://carshop.com/terms",
    privacyLink: "https://carshop.com/privacy-requests",
  },
  "65aff26f3772ad00136433c1": {
    termsLink: "https://carshop.com/terms",
    privacyLink: "https://carshop.com/privacy-requests",
  },
  "668ebf435a165bbdf1291690": {
    termsLink: "https://carshop.com/terms",
    privacyLink: "https://carshop.com/privacy-requests",
  },
  "668eccbb543b9c192cd2de83": {
    termsLink: "https://carshop.com/terms",
    privacyLink: "https://carshop.com/privacy-requests",
  },
  "65aec4787640ce001303358b": {
    termsLink: "https://www.auditurnersville.com/penske-terms-of-use.htm",
    privacyLink:
      "https://www.auditurnersville.com/tools/complyauto/privacy-policy.htm",
  },
  "65aea1867640ce001303232f": (currentUrl) => {
    if (currentUrl.includes("cadillacofturnersville.com")) {
      return {
        termsLink:
          "https://www.cadillacofturnersville.com/terms-of-use-policies/",
        privacyLink: "https://www.cadillacofturnersville.com/privacy-policies/",
      };
    } else if (currentUrl.includes("MatadorEngagementPlatformFrontEnd")) {
      return {
        termsLink:
          "https://www.chevroletofturnersville.com/terms-of-use-policies/",
        privacyLink:
          "https://www.chevroletofturnersville.com/privacy-policies/",
      };
    } else {
      return {
        termsLink:
          "https://www.chevroletofturnersville.com/terms-of-use-policies/",
        privacyLink:
          "https://www.chevroletofturnersville.com/privacy-policies/",
      };
    }
  },
  "65aeb5c1007c5a001325afeb": {
    termsLink: "https://www.hyundaiofturnersville.com/penske-terms-of-use.htm",
    privacyLink:
      "https://www.hyundaiofturnersville.com/tools/complyauto/privacy-policy.htm",
  },
  "65aec9f9d2e9420013ac4d91": {
    termsLink: "https://www.porschemonmouth.com/terms-of-use.htm",
    privacyLink: "https://www.porschemonmouth.com/privacy.aspx",
  },
  "67589fbe1874c5e3e50c97ad": {
    termsLink: "https://www.atlantatoyota.com/penske-terms-of-use.htm",
    privacyLink:
      "https://www.atlantatoyota.com/tools/complyauto/privacy-policy.htm",
  },
  "674600f51b9249a0865deae2": {
    termsLink: "https://www.bmwofturnersville.com/terms-of-use-policies/",
    privacyLink: "https://www.bmwofturnersville.com/privacy-policies/",
  },
  "67460d8a13e8a468013e818a": {
    termsLink: "https://applications.edealer.ca/PrivacyPolicy.htm",
    privacyLink: "https://applications.edealer.ca/PrivacyPolicy.htm",
  },
  "677d612d99c7f4940a3bedb7": {
    termsLink: "https://www.nissanofturnersville.com/terms-of-use-policies/",
    privacyLink: "https://www.nissanofturnersville.com/privacy-policies/",
  },
  // stg test
  "67d2d80a9438722141060c44": {
    termsLink: "https://projects.ubicross.pro/matador/terms.html",
    privacyLink: "https://projects.ubicross.pro/matador/privacy.html",
  },
};

export default customTermLocationIds;
