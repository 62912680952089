import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Turnstile } from "@marsidev/react-turnstile";
import { useChatContext } from "../../contexts/ChatContext";
import { useLocation } from "react-router-dom";

const TurnstileWidget = forwardRef((props, ref) => {
  const { setTurnstileStatus, handleError } = props;
  const [currentUrl, setCurrentUrl] = useState("");
  const location = useLocation();
  const retryCountRef = useRef(0);

  useEffect(() => {
    setUrl();
  }, []);

  const setUrl = () => {
    let params = new URLSearchParams(location.search);
    let urlValue = params.get("url");

    if (location.hash) {
      const newUrl = urlValue + location.hash;
      setCurrentUrl(newUrl);
    } else {
      setCurrentUrl(urlValue);
    }
  };

  const localRef = useRef(null);

  const { setTurnstileToken, readOnly } = useChatContext();

  useImperativeHandle(ref, () => ({
    reset: () => {
      localRef?.current?.reset();
    },
  }));

  const onError = () => {
    if (retryCountRef.current < 3) {
      retryCountRef.current += 1;
      localRef.current?.reset();
    } else {
      handleError?.("Verification error");
    }
  };

  if (readOnly || currentUrl?.includes("#matador-dashboard-preview")) {
    return null;
  }

  return (
    <Turnstile
      ref={localRef}
      siteKey="0x4AAAAAAA5YSuWDpwpgy3tz"
      onSuccess={(token) => {
        setTurnstileStatus("solved");
        setTurnstileToken(token);
        retryCountRef.current = 0;
      }}
      onError={onError}
      onExpire={() => {
        localRef.current?.reset();
      }}
      options={
        {
          //theme: "light",
          //size: "normal",
        }
      }
    />
  );
});

export default TurnstileWidget;
