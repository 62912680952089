import React from "react";
import { strings } from "../utils/localization";
import { ReactComponent as NewLogo } from "../assets/images/common/new_logo.svg";
import hiddenFooterLocationIds from "../utils/hiddenFooterLocationIds";

const FooterCoupon = (props) => {
  const { termsLink, theme, lang, locationId } = props;

  const showFooter = !hiddenFooterLocationIds?.includes(locationId);

  const setTheme = (theme) => {
    if (theme === "dark") {
      return (
        <a
          href="https://matador.ai"
          target="_blank"
          aria-label="Matador"
          className="app-name-dark"
        >
          <div className="anticon">
            <NewLogo />
          </div>
        </a>
      );
    } else {
      return (
        <a
          href="https://matador.ai"
          target="_blank"
          aria-label="Matador"
          className="app-name"
        >
          <NewLogo width={70.21} height={16.24} />
        </a>
      );
    }
  };

  const setFooterText = (lang, theme) => {
    if (lang === "fr") {
      return (
        <>
          {showFooter && (
            <div
              style={{ display: "flex", alignItems: "center" }}
              className="footer-first-item"
            >
              {strings._props.fr.BY}
              {setTheme(theme)}
            </div>
          )}

          <div className="footer-last-item">
            {strings._props.fr.USE_AS_SUBJECT_TO}
            <a
              target="_blank"
              aria-label="terms and conditions link"
              href={
                termsLink
                  ? termsLink
                  : "http://matador.ai/?utm_source=matador%20connect%20widget&utm_medium=link&utm_campaign=widget%20referral"
              }
            >
              {strings._props.fr.TERMS}
            </a>
            .{strings._props.fr.TEXT_MESSAGE_FEES_MAY_APPLY}
          </div>
        </>
      );
    } else if (lang === "sp") {
      return (
        <>
          {showFooter && (
            <div
              style={{ display: "flex", alignItems: "center" }}
              className="footer-first-item"
            >
              {strings._props.es.BY}
              {setTheme(theme)}
            </div>
          )}

          <div className="footer-last-item">
            {strings._props.es.USE_AS_SUBJECT_TO}
            <a
              target="_blank"
              href={
                termsLink
                  ? termsLink
                  : "http://matador.ai/?utm_source=matador%20connect%20widget&utm_medium=link&utm_campaign=widget%20referral"
              }
            >
              {strings._props.es.TERMS}
            </a>
            .{strings._props.es.TEXT_MESSAGE_FEES_MAY_APPLY}
          </div>
        </>
      );
    } else {
      return (
        <>
          {showFooter && (
            <div
              style={{ display: "flex", alignItems: "center" }}
              className="footer-first-item"
            >
              {strings._props.en.BY}
              {setTheme(theme)}
            </div>
          )}

          <div className="footer-last-item">
            {strings._props.en.USE_AS_SUBJECT_TO}
            <a
              target="_blank"
              href={
                termsLink
                  ? termsLink
                  : "http://matador.ai/?utm_source=matador%20connect%20widget&utm_medium=link&utm_campaign=widget%20referral"
              }
            >
              {strings._props.en.TERMS}
            </a>
            .{strings._props.en.TEXT_MESSAGE_FEES_MAY_APPLY}
          </div>
        </>
      );
    }
  };

  return (
    <div className="nav-footer" style={{ marginTop: "10%" }}>
      {setFooterText(lang, theme)}
    </div>
  );
};

export default FooterCoupon;
