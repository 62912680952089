import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

import notificationSound from "./assets/notification.wav";
import outboundSound from "./assets/outbound.wav";
import AiChatApi from "../components/Messaging/ai-chat-api";

const ChatContext = createContext();

export const useChatContext = () => useContext(ChatContext);

const formatTimestamp = (date) => {
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  return new Intl.DateTimeFormat("default", options)
    .format(date)
    .replace(",", "");
};

export const ChatProvider = ({ children }) => {
  const [chatId, setChatId] = useState(() => {
    const savedChatId = localStorage.getItem("-CHAT-ID-");
    return savedChatId || null;
  });

  const [messages, setMessages] = useState(() => {
    const savedMessages = localStorage.getItem("messages");
    return savedMessages ? JSON.parse(savedMessages) : [];
  });

  const [isSending, setIsSending] = useState(false);
  const [chatActive, setChatActive] = useState(false);

  const [logs, setLogs] = useState([]);
  const [chatCustomer, setChatCustomer] = useState(null);
  const [chatAppointment, setChatAppointment] = useState(null);
  const [readOnly, setReadOnly] = useState(false);
  const [turnstileToken, setTurnstileToken] = useState("");
  const isInChatView = useRef(true);
  const cancelTokenSourceRef = useRef(null);
  const cancelTokenStatusRef = useRef(null);

  const pollingIntervals = useRef({});

  const getUserId = () => {
    let storedUserId = localStorage.getItem("-USER-ID-") || "";
    return storedUserId;
  };

  const setUserId = (id) => {
    localStorage.setItem("-USER-ID-", id);
  };

  const getPendingRequests = () => {
    const stored = localStorage.getItem("pendingRequests");
    return stored ? JSON.parse(stored) : [];
  };

  const setPendingRequests = (pending) => {
    localStorage.setItem("pendingRequests", JSON.stringify(pending));
  };

  const addPendingRequest = (request) => {
    const pending = getPendingRequests();
    pending.push(request);
    setPendingRequests(pending);
  };

  const updatePendingRequest = (idempotencyKey, updates) => {
    const pending = getPendingRequests();
    const updated = pending.map((req) =>
      req.idempotencyKey === idempotencyKey ? { ...req, ...updates } : req
    );
    setPendingRequests(updated);
  };

  useEffect(() => {
    const pendingRequests = getPendingRequests();
    if (chatId || pendingRequests.length > 0) {
      setChatActive(true);
    }
  }, [chatId]);

  useEffect(() => {
    if (chatId) {
      localStorage.setItem("-CHAT-ID-", chatId);
    }
  }, [chatId]);

  useEffect(() => {
    if (!readOnly) {
      checkInactiveConversation();
    }
  }, [messages]);

  useEffect(() => {
    localStorage.setItem("messages", JSON.stringify(messages));
  }, [messages]);

  useEffect(() => {
    const pending = getPendingRequests();
    pending.forEach((req) => {
      if (req.status === "sent") {
        pollMessageStatus({
          idempotencyKey: req.idempotencyKey,
          locationId: req.locationId,
        });
      }
    });

    return () => {
      cancelStatusRequest();
    };
  }, []);

  useEffect(() => {
    const handleStorage = (event) => {
      if (event.key === "messages" && event.newValue) {
        const updatedMessages = JSON.parse(event.newValue);
        if (Array.isArray(updatedMessages)) {
          setMessages(updatedMessages);
        }
      }
      if (
        event.key === "-CHAT-ID-" &&
        (event.newValue || event.newValue === "")
      ) {
        setChatId(event.newValue);
      }
      if (event.key === "isSending") {
        setIsSending(event.newValue === "true");
      }
    };

    if (!readOnly) {
      window.addEventListener("storage", handleStorage);
    }

    return () => window.removeEventListener("storage", handleStorage);
  }, [readOnly]);

  const playNotificationSound = () => {
    const audio = new Audio(notificationSound);
    audio.play();
  };

  const playOutboundSound = () => {
    const audio = new Audio(outboundSound);
    audio.play();
  };

  const pollMessageStatus = ({ idempotencyKey, locationId }) => {
    if (pollingIntervals.current[idempotencyKey]) return;
    setLoading(true);
    pollingIntervals.current[idempotencyKey] = setInterval(async () => {
      try {
        cancelTokenStatusRef.current = axios.CancelToken.source();

        const response = await AiChatApi.checkMessageStatus({
          idempotencyKey,
          locationId,
          cancelToken: cancelTokenStatusRef.current.token,
        });
        const status = response.data.status;
        if (status === "completed" || status === "failed") {
          setLoading(false);

          clearInterval(pollingIntervals.current[idempotencyKey]);
          delete pollingIntervals.current[idempotencyKey];
          updatePendingRequest(idempotencyKey, { status });
          if (status === "completed" && isInChatView?.current) {
            handleSuccessful({ response, playSound: false });
          }
        }
      } catch (error) {
        clearInterval(pollingIntervals.current[idempotencyKey]);
        delete pollingIntervals.current[idempotencyKey];
        updatePendingRequest(idempotencyKey, { status: "failed" });
        setLoading(false);
      }
    }, 3000);
  };

  const setLoading = (value) => {
    setIsSending(value);
    localStorage.setItem("isSending", value.toString());
  };

  const triggerUrlRedirection = ({ url }) => {
    window.top.postMessage(
      {
        type: "MATADOR_REDIRECT_TO_URL",
        url,
      },
      "*"
    );
  };

  const updateContact = async ({
    firstName,
    lastName,
    phoneNumber,
    email,
    appointmentDate,
    appointmentTime,
    language,
    messageId,
    customLocationId,
    setupLocationId,
  }) => {
    if (readOnly) return;
    const userId = getUserId();
    let date = "";
    let time = "";

    if (appointmentDate) {
      date = moment(appointmentDate).format("MMMM DD, YYYY");
    }
    if (appointmentTime) {
      time = moment(appointmentTime, "h:mm A").format("HH:mm");
    }

    const appointmentData = {
      language,
      turnstileToken,
      ...(chatId && { chatId }),
      ...(userId && { userId }),
      ...(firstName && { firstName }),
      ...(lastName && { lastName }),
      ...(phoneNumber && { phoneNumber }),
      ...(email && { email }),
      ...(date && { appointmentDate: date }),
      ...(time && { appointmentTime: time }),
    };

    setLoading(true);

    try {
      playOutboundSound();
      cancelTokenSourceRef.current = axios.CancelToken.source();

      const response = await AiChatApi.updateContact({
        locationId: customLocationId || setupLocationId,
        param: appointmentData,
        cancelToken: cancelTokenSourceRef.current.token,
      });

      if (response.data.success && isInChatView?.current) {
        const { message, type, inventory, _id, customer, appointment } =
          response.data.response;

        playNotificationSound();

        setChatCustomer(customer);
        setChatAppointment(appointment);

        const botNow = new Date();
        const botMessages = [
          {
            _id,
            text: message,
            type,
            inventory,
            role: "assistant",
            createdAt: botNow.toISOString(),
            timestamp: formatTimestamp(botNow),
          },
        ];

        if (messageId) {
          updateIsSubmitted({ messageId });
        }
        setMessages((prev) => [...prev, ...botMessages]);
        setLogs((prevLogs) => [...prevLogs, { ...response.data }]);
      } else {
        throw new Error("Error in response");
      }
    } catch (error) {
      throw error?.response?.data?.message || "Something went wrong";
    } finally {
      setLoading(false);
      cancelTokenSourceRef.current = null;
    }
  };

  const handleSuccessful = ({ response, playSound = true }) => {
    const { message, type, inventory, customer, appointment, chatSession } =
      response.data.response;
    const { idempotencyKey } = response.data;
    if (playSound) {
      playNotificationSound();
    }
    if (customer?._id) {
      setUserId(customer._id);
    }
    if (chatSession?._id) {
      setChatId(chatSession._id);
    }
    setChatCustomer(customer);
    setChatAppointment(appointment);
    const botNow = new Date();
    const botMessages = [
      {
        idempotencyKey,
        text: message,
        type,
        inventory,
        role: "assistant",
        createdAt: botNow.toISOString(),
        timestamp: formatTimestamp(botNow),
      },
    ];
    if (!messages?.find((msg) => msg.idempotencyKey === idempotencyKey)) {
      setMessages((prev) => [...prev, ...botMessages]);
    }

    setLogs((prevLogs) => [...prevLogs, { ...response.data }]);
  };

  const sendMessage = async ({
    text,
    url,
    messageId,
    playSound = true,
    isRetry = false,
    language,
    customLocationId,
    setupLocationId,
  }) => {
    if (readOnly) return;
    const userId = getUserId();

    if (!text.trim()) return;

    cancelTokenSourceRef.current = axios.CancelToken.source();

    let randomId = uuidv4();
    const now = new Date();
    const userMessage = messageId
      ? messages.find((msg) => msg._id === messageId)
      : {
          text,
          role: "user",
          timestamp: formatTimestamp(now),
          createdAt: now.toISOString(),
          _id: randomId,
          status: "sending",
        };

    if (!messageId) {
      setMessages((prev) => [...prev, userMessage]);
    } else if (isRetry) {
      setMessages((prev) =>
        prev.map((msg) =>
          msg._id === messageId ? { ...msg, status: "sending" } : msg
        )
      );
    }

    setLoading(true);

    try {
      playOutboundSound();
      const idempotencyKey = uuidv4();

      const data = {
        message: text,
        language,
        turnstileToken,
        //url,
        idempotencyKey,
      };

      if (chatId) {
        data.chatId = chatId;
      }
      if (userId) {
        data.userId = userId;
      }
      if (setupLocationId) {
        data.setupLocationId = setupLocationId;
      }

      addPendingRequest({
        status: "sent",
        locationId: customLocationId || setupLocationId,
        idempotencyKey,
      });

      const response = await AiChatApi.sendMessage({
        locationId: customLocationId || setupLocationId,
        param: data,
        cancelToken: cancelTokenSourceRef.current.token,
      });

      if (response.data.success && isInChatView?.current) {
        updatePendingRequest(idempotencyKey, { status: response.data.status });
        handleSuccessful({ response, playSound });
      } else {
        throw new Error("Error in response");
      }
    } catch (error) {
      handleError({ error, newMessageId: userMessage._id });
    } finally {
      setLoading(false);
      cancelTokenSourceRef.current = null;
    }
  };

  const updateIsSubmitted = ({ messageId }) => {
    const updatedMessages = messages.map((message) => {
      if (message._id === messageId) {
        return { ...message, isSubmitted: true };
      }
      return message;
    });
    setMessages(updatedMessages);
  };

  const handleError = ({ error, newMessageId }) => {
    if (axios.isCancel(error)) {
      return;
    }
    setMessages((prev) =>
      prev.map((msg) =>
        msg._id === newMessageId
          ? {
              ...msg,
              status: "failed",
              error: error?.response?.data?.error || "Failed to send message",
            }
          : msg
      )
    );
    throw error?.response?.data?.message || "Something went wrong";
  };

  const clearChat = (removeUser = false) => {
    setMessages([]);
    setLogs([]);
    localStorage.removeItem("-CHAT-ID-");
    localStorage.removeItem("messages");
    localStorage.removeItem("isSending");
    setChatId("");
    setLoading(false);
    if (removeUser) {
      localStorage.removeItem("-USER-ID-");
    }
    setPendingRequests([]);
    setChatActive(false);
    clearPollingIntervals();
    cancelStatusRequest();
  };

  const checkInactiveConversation = () => {
    if (isSending) return;
    const messagesData = JSON.parse(localStorage.getItem("messages"));
    if (messagesData?.length === 0 || !messagesData) {
      return;
    }

    const lastMessage = messagesData[messagesData.length - 1];
    if (!lastMessage || !lastMessage.createdAt) return;

    const lastMessageTime = new Date(lastMessage.createdAt).getTime();
    const now = Date.now();
    const minLimit = parseInt(process.env.REACT_APP_CHAT_AI_EXPIRATION_MINUTES);
    const timeLimit = minLimit * 60 * 1000;

    if (now - lastMessageTime > timeLimit) {
      clearChat(true);
      return "CLEAR";
    }
  };

  const setPreloadedMessages = (prefillData) => {
    if (!prefillData?.messages) return;
    setReadOnly(true);
    setChatId(prefillData?._id || "");

    const formattedMessages = prefillData.messages.map((msg) => ({
      ...msg,
      _id: msg._id,
      text: msg.message,
      role: msg.role,
      type: msg.type,
      createdAt: msg.createdAt,
      timestamp: formatTimestamp(new Date(msg.createdAt)),
    }));

    setMessages(formattedMessages);

    if (prefillData.customer) {
      const randomId = uuidv4();
      setUserId(randomId);

      setChatCustomer({
        ...chatCustomer,
        fullName: prefillData.customer.fullName,
        phone: prefillData.customer.phone,
      });
    }

    if (prefillData.appointment) {
      setChatAppointment({
        ...chatAppointment,
        date: prefillData.appointment.date,
        time: prefillData.appointment.time,
      });
    }
  };

  const setIsInChatView = (value) => {
    isInChatView.current = value;
  };

  const cancelApiRequest = () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel("Request canceled by user.");
      cancelTokenSourceRef.current = null;
    }
  };

  const cancelStatusRequest = () => {
    if (cancelTokenStatusRef.current) {
      cancelTokenStatusRef.current.cancel("Request canceled by user.");
      cancelTokenStatusRef.current = null;
    }
  };

  const uniqueMessages = (() => {
    const seenKeys = new Set();

    return messages.filter((message) => {
      if (!message.idempotencyKey) return true;
      if (seenKeys.has(message.idempotencyKey)) return false;

      seenKeys.add(message.idempotencyKey);
      return true;
    });
  })();

  const clearPollingIntervals = () => {
    Object.values(pollingIntervals.current).forEach((intervalId) => {
      clearInterval(intervalId);
    });
    pollingIntervals.current = {};
  };

  const value = {
    messages: uniqueMessages,
    sendMessage,
    isSending,
    chatId,
    logs,
    clearChat,
    chatCustomer,
    chatAppointment,
    triggerUrlRedirection,
    updateIsSubmitted,
    updateContact,
    setPreloadedMessages,
    readOnly,
    setTurnstileToken,
    turnstileToken,
    isInChatView,
    setIsInChatView,
    cancelApiRequest,
    checkInactiveConversation,
    setChatId,
    chatActive,
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};
