import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import "../styles/createCoupon.css";
import FooterCoupon from "../components/FooterCoupon";
import SwipeButton from "../components/Buttons/SwipeButton";
import couponMainActions from "../components/modules/couponMainActions";
import { ReactComponent as CloseIcon } from "../assets/images/common/closeIcon.svg";
import useAnalytics from "../utils/useAnalytics";
import { useParams } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const ActionSheet = React.forwardRef(
  ({ closeOnBgTap, sheetStyle, onClose, children }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const touchStartY = useRef(0);
    const touchDisplacement = useRef(0);
    const sheetHeight = window.innerHeight;
    const [{ y }, api] = useSpring(() => ({ y: 0 }));

    React.useImperativeHandle(ref, () => ({
      open: () => setIsOpen(true),
      close: () => setIsOpen(false),
    }));

    const handleTouchStart = (e) => {
      touchStartY.current = e.touches[0].clientY;
    };

    const handleTouchMove = (e) => {
      const currentY = e.touches[0].clientY;
      const displacement = currentY - touchStartY.current;
      if (displacement > 0) {
        touchDisplacement.current = displacement;
        api.start({ y: displacement });
      }
    };

    const handleTouchEnd = () => {
      if (touchDisplacement.current > 100) {
        api.start({
          y: sheetHeight,
          onRest: () => {
            if (onClose) onClose();
            setIsOpen(false);
            api.start({ y: 0 });
          },
        });
      } else {
        api.start({ y: 0 });
      }
      touchDisplacement.current = 0;
    };

    if (!isOpen) return null;

    return (
      <animated.div
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          backgroundColor: "white",
          transform: y.to((y) => `translateY(${y}px)`),
          ...sheetStyle,
          touchAction: "none",
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {children}
        {!closeOnBgTap && (
          <div
            onClick={onClose}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "transparent",
            }}
          />
        )}
      </animated.div>
    );
  }
);

const MobileCoupon = () => {
  const { token: locationId, lang } = useParams();
  const language = lang === "es" ? "sp" : lang;

  const [couponData, setCouponData] = useState();
  const ref = useRef();
  const [contentVisible, setContentVisible] = useState(true);
  const { sendCommEngagementEvent } = useAnalytics(
    couponData?.GATrackingNumber
  );

  const handleOpen = () => {
    ref.current.open();
  };

  const handleClose = () => {
    onClose();
    ref.current.close();
  };

  useEffect(() => {
    if (couponData) {
      handleOpen();
    }
  }, [couponData]);

  const GAEventTracking = () => {
    sendCommEngagementEvent({ widget: "Mobile Coupon", status: "start" });
  };

  useEffect(() => {
    if (couponData) {
      sendCommEngagementEvent({ widget: "Mobile Coupon", status: "load" });
    }
  }, [couponData]);

  useEffect(() => {
    const fill = async () => {
      let result = await couponMainActions.getCoupon(locationId);
      if (result.success) {
        setCouponData(result.data);
      }
    };
    if (locationId) {
      fill();
    }
  }, [locationId]);

  if (!couponData) return null;

  const title =
    couponData.title?.find((x) => x.language === language)?.text ||
    couponData.title?.find((x) => x.language === "en")?.text;
  const description =
    couponData.description?.find((x) => x.language === language)?.text ||
    couponData.description?.find((x) => x.language === "en")?.text;
  const buttonTexts =
    couponData.buttonTexts?.find((x) => x.language === language)?.text ||
    couponData.buttonTexts?.find((x) => x.language === "en")?.text;
  const smsText =
    couponData.smsTemplate?.find((x) => x.language === language)?.text ||
    couponData.smsTemplate?.find((x) => x.language === "en")?.text;

  const getContent = () => {
    return (
      <div
        className={`phone-under-nav-wrapper`}
        style={{ backgroundColor: couponData.primaryBackgroundColor }}
      >
        <div className="nav-header-border" />
        <div
          onClick={handleClose}
          style={{
            padding: 10,
            cursor: "pointer",
            right: 20,
            top: 15,
            position: "absolute",
            fontFamily: "Arial, sans-serif",
          }}
        >
          <CloseIcon stroke={couponData.titleColor} />
        </div>
        <div className="logo-item">
          {couponData.logoImageUrl && (
            <img src={couponData.logoImageUrl} alt="Logo" />
          )}
        </div>
        <div className="title-item">
          {title && (
            <div className="title" style={{ color: couponData.titleColor }}>
              {title}
            </div>
          )}
        </div>
        <div className="note-item">
          {description && (
            <div
              className="note"
              style={{ color: couponData.descriptionColor }}
            >
              {description}
            </div>
          )}
        </div>
        <div className="button-item">
          <SwipeButton
            backgroundColor={couponData.backgroundColor}
            color={couponData.buttonColor}
            text={buttonTexts}
            phoneNumber={couponData.organization_number}
            smsText={smsText}
            onSuccess={handleClose}
            lang={language}
            eventTracking={GAEventTracking}
          />
        </div>
        <FooterCoupon
          lang={language}
          termsLink={couponData.termsConditionsLink}
          theme={couponData.theme}
          locationId={couponData._location_id}
        />
      </div>
    );
  };

  const onClose = () => {
    window.top.postMessage("matador_coupon_message_hidden", "*");
    setContentVisible(false);
  };

  if (!contentVisible) return null;

  return (
    <ActionSheet
      closeOnBgTap={false}
      sheetStyle={{ backgroundColor: "transparent" }}
      ref={ref}
      onClose={onClose}
    >
      <Container>{getContent()}</Container>
    </ActionSheet>
  );
};

export default MobileCoupon;
